import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet fràgil i petit que creix amb gran nombre d’individus, i presenta un capell d’1 a 1,5 cm de diàmetre en forma de ventall de color blanc, cotonós cap a la part superior i amb pèls molt fins. A la part inferior presenta làmines blanques o cremoses i finalment marrons per la caiguda de les espores, amples, separades i amb l’aresta dentada. Les espores són marró clar en massa, globoses o en forma d’ametla amb solcs i de 4,7-7,7 x 4,6-5,8 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      